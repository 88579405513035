<template>
  <a-row class="view-data" :gutter="20" style="padding: 0px 10px">
    <a-col :span="6">
      <div class="label">ID</div>
      <div class="value">
        {{ supplier.id }}
      </div>
    </a-col>
    <a-col :span="18">
      <div class="label">Nome do Fornecedor</div>
      <div class="value">
        {{ supplier.first_name }}
        {{ supplier.last_name }}
      </div>
    </a-col>
    <a-col :span="24">
      <div class="label">E-mail</div>
      <div class="value">{{ supplier.email }}</div>
    </a-col>
    <a-col :span="12">
      <div class="label">Data de nascimento</div>
      <div class="value">{{ formatDateTime(supplier.birthday) }}</div>
    </a-col>
    <a-col :span="12">
      <div class="label">CPF</div>
      <div class="value">{{ supplier.cpf }}</div>
    </a-col>
    <a-col :span="8">
      <div class="label">RG</div>
      <div class="value">{{ supplier.rg }}</div>
    </a-col>
    <a-col :span="8">
      <div class="label">Orgão emissor</div>
      <div class="value">{{ supplier.rg_emissor }}</div>
    </a-col>
    <a-col :span="8">
      <div class="label">Estado</div>
      <div class="value">{{ supplier.rg_state }}</div>
    </a-col>
    <a-col :span="12">
      <div class="label">Telefone fixo</div>
      <div class="value">{{ supplier.phone }}</div>
    </a-col>
    <a-col :span="12">
      <div class="label">Celular</div>
      <div class="value">{{ supplier.mobile_phone }}</div>
    </a-col>
    <a-col :span="24">
      <div class="label">Logradouro</div>
      <div class="value">
        {{ supplier.location_street }}
      </div>
    </a-col>
    <a-col :span="8">
      <div class="label">Número</div>
      <div class="value">
        {{ supplier.location_number }}
      </div>
    </a-col>
    <a-col :span="16">
      <div class="label">Complemento</div>
      <div class="value">
        {{ supplier.location_complement }}
      </div>
    </a-col>
    <a-col :span="24">
      <div class="label">Bairro</div>
      <div class="value">
        {{ supplier.location_neighborhood }}
      </div>
    </a-col>
    <a-col :span="8">
      <div class="label">Cidade</div>
      <div class="value">
        {{ supplier.location_city }}
      </div>
    </a-col>
    <a-col :span="8">
      <div class="label">Estado</div>
      <div class="value">
        {{ supplier.location_state }}
      </div>
    </a-col>
    <a-col :span="8">
      <div class="label">CEP</div>
      <div class="value">{{ supplier.cep }}</div>
    </a-col>
  </a-row>
</template>

<script>
import { format, parse } from "date-fns";

export default {
  props: {
    supplier: {
      type: Object,
    },
  },
  methods: {
    formatDateTime(date) {
      if (date.length > 22) {
        let theDate = parse(date.split("T")[0], "yyyy-MM-dd", new Date());
        return format(theDate, "dd/MM/yyyy");
      } else {
        return date;
      }
    },
  },
};
</script>
